#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "fonts/boon.css";

body {
  margin: 0;
  padding: 0;
  font-family: "boon","Roboto", sans-serif;
  font-size: 0.85rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}

a, a:hover, a:visited {
  color: #faad14;
}
a:hover {
  font-weight: 500;
}

.table td, .table th {
padding: .5rem;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fefefe;
}

.table-striped  > tbody > tr:hover > td,
.table-striped  > tbody > tr:hover > th {
  background-color: #E8DEB1;
  cursor: pointer;
}

.table-striped  > thead > tr> th {
  vertical-align: text-top;
  text-align: center;
  background-color: #ededed;
}

ul.dropdown-menu > li:hover{
  background-color: #E8DEB1;
}

.page-link {
  color: #faad14;
}

.page-link:hover {
  color: #faad14;
  font-weight: 500;
}

.page-item.active .page-link {
  z-index: 1;
    color: #fff;
    background-color: #faad14;
    border-color: #eee;
}

.ant-layout-footer {
  padding: 5px 5px;
}

.datagrid-image{
  width: auto;
  height: 65px;
}

.form-control{
  font-size: 0.85rem;
}

.live-clock {
  font-size: 1.5em;
  font-weight: 700;
  background-color: gold;
  color: #fff;
  padding: 0 5px 0 5px;
  border-radius: 0.2em;
}

.ant-layout-sider-collapsed .nav-text {
    display: none;
}


.date-filter-comparator .form-control {
  display: none;
}

#pageDropDown .dropdown-toggle:after {
    display: inline-block;
}

.react-bootstrap-table{
  overflow-x: scroll;
  width: 100%;
}
