/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 23, 2019 */



@font-face {
    font-family: 'boon';
    src: url('boon-light-webfont.eot');
    src: url('boon-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('boon-light-webfont.woff2') format('woff2'),
         url('boon-light-webfont.woff') format('woff'),
         url('boon-light-webfont.ttf') format('truetype'),
         url('boon-light-webfont.svg#boonlight') format('svg');
    font-weight: 200;
    font-style: normal;

}


@font-face {
    font-family: 'boon';
    src: url('boon-bolditalic-webfont.eot');
    src: url('boon-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('boon-bolditalic-webfont.woff2') format('woff2'),
         url('boon-bolditalic-webfont.woff') format('woff'),
         url('boon-bolditalic-webfont.ttf') format('truetype'),
         url('boon-bolditalic-webfont.svg#boonbold_italic') format('svg');
    font-weight: 700;
    font-style: italic;

}


@font-face {
    font-family: 'boon';
    src: url('boon-bold-webfont.eot');
    src: url('boon-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('boon-bold-webfont.woff2') format('woff2'),
         url('boon-bold-webfont.woff') format('woff'),
         url('boon-bold-webfont.ttf') format('truetype'),
         url('boon-bold-webfont.svg#boonbold') format('svg');
    font-weight: 700;
    font-style: normal;

}



@font-face {
    font-family: 'boon';
    src: url('boon-mediumitalic-webfont.eot');
    src: url('boon-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('boon-mediumitalic-webfont.woff2') format('woff2'),
         url('boon-mediumitalic-webfont.woff') format('woff'),
         url('boon-mediumitalic-webfont.ttf') format('truetype'),
         url('boon-mediumitalic-webfont.svg#boonmedium_italic') format('svg');
    font-weight: 500;
    font-style: italic;

}




@font-face {
    font-family: 'boon';
    src: url('boon-medium-webfont.eot');
    src: url('boon-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('boon-medium-webfont.woff2') format('woff2'),
         url('boon-medium-webfont.woff') format('woff'),
         url('boon-medium-webfont.ttf') format('truetype'),
         url('boon-medium-webfont.svg#boonmedium') format('svg');
    font-weight: 500;
    font-style: normal;

}


@font-face {
    font-family: 'boon';
    src: url('boon-italic-webfont.eot');
    src: url('boon-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('boon-italic-webfont.woff2') format('woff2'),
         url('boon-italic-webfont.woff') format('woff'),
         url('boon-italic-webfont.ttf') format('truetype'),
         url('boon-italic-webfont.svg#boonitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'boon';
    src: url('boon-regular-webfont.eot');
    src: url('boon-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('boon-regular-webfont.woff2') format('woff2'),
         url('boon-regular-webfont.woff') format('woff'),
         url('boon-regular-webfont.ttf') format('truetype'),
         url('boon-regular-webfont.svg#boonregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'boon';
    src: url('boon-lightitalic-webfont.eot');
    src: url('boon-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('boon-lightitalic-webfont.woff2') format('woff2'),
         url('boon-lightitalic-webfont.woff') format('woff'),
         url('boon-lightitalic-webfont.ttf') format('truetype'),
         url('boon-lightitalic-webfont.svg#boonlight_italic') format('svg');
    font-weight: 200;
    font-style: italic;

}
