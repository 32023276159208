#pageDropDown{
    float: left;
}

.dropdown-menu {
    float: left;
}

.react-bootstrap-table-pagination-total{
    float: left;
}