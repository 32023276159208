.pagination {
    float:right;
}

#pageDropDown {
  color: #fff;
  background-color: #faad14;
  border-color: #eee;
  padding: 0.325rem;
}

.react-bs-table-sizePerPage-dropdown > ul.dropdown-menu {
  position: absolute;
  top: -11rem;
  left: 0rem;
  z-index: 1000;
  float: left;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  }

.dropdown > .caret , .dropup > .caret, .react-bootstrap-table-sort-order > .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .react-bootstrap-table-sort-order > .caret {
    color: red;
  }

  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
  }

.dropdown-toggle::after {
    display:;
  }
